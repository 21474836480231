<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    max-width="465px"
    content-class="rounded-xl"
  >
    <v-overlay class="justify-center align-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="8"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="title-name">
          Please select reason to move this lead to <br />
          Irrelevant.
        </div>
        <v-btn icon>
          <v-icon @click="modalClosed" size="26">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="sub-title py-6" v-if="client_detail">
          {{ client_detail.name }}
          <div style="opacity: 0.7">
            {{ client_detail.user.mobile_display }} |
            {{ client_detail.user.email }}
          </div>
        </div>
        <v-form lazy-validation ref="form">
          <v-row no-gutters>
            <v-col cols="12">
              <v-select
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="form.reason"
                :items="reason_list"
                item-text="title"
                item-value="id"
                flat
                outlined
                dense
                label="Reason"
                class="rounded-lg"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" v-if="form.reason == 0">
              <v-textarea
                flat
                :rows="3"
                outlined
                v-model="form.reason_other"
                label="Type here..."
                class="rounded-lg"
                :rules="[$rules.required]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="submit"
          class="text-capitalize px-8 my-2 rounded-lg"
          height="40px"
          color="primary"
          :loading="btnLoading"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      btnLoading: false,
      client_detail: null,
      reason_list: [],
      form: {
        reason: null,
        reason_other: "",
      },
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  computed: {
    ...mapGetters({
      irrelevantModal: "clientManagement/irrelevantModal",
    }),
    show: {
      get() {
        return this.irrelevantModal.show;
      },
      set(value) {
        this.toggleIrrelevantModal({ show: value });
      },
    },
  },
  methods: {
    ...mapActions({
      toggleIrrelevantModal: "clientManagement/toggleIrrelevantModal",
      showSnackbar: "app/showSnackbar",
      reloadClientApi: "clientManagement/reloadClientApi",
    }),

    async openModal() {
      this.loading = true;
      await this.getClientDetail();
      await this.getReasonList();
      this.loading = false;
    },

    modalClosed() {
      this.$refs.form.reset();
      this.form = {
        reason: null,
        reason_other: "",
      };
      this.toggleIrrelevantModal({ show: false, id: null });
    },

    getClientDetail() {
      const params = {
        client_id: this.irrelevantModal.id,
      };

      const successHandler = (res) => {
        this.client_detail = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.client.detail, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getReasonList() {
      const params = {
        reason_type: "irrelevant",
      };

      const onSuccess = (res) => {
        this.reason_list = res.data.data;
        this.reason_list.push({
          id: 0,
          title: "Other Reason",
          type: "other",
        });
      };

      const onFinally = () => {
        this.loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
        onFinally,
      });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        const data = {
          client: this.irrelevantModal.id,
        };

        if (this.form.reason == 0) {
          data["reason_other"] = this.form.reason_other;
        } else {
          data["reason"] = this.form.reason;
        }

        const successHandler = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });

          this.modalClosed();
          this.reloadClientApi({ reload: true });
        };

        const finallyHandler = () => {
          this.btnLoading = false;
        };

        this.$request(this.$keys.POST, this.$urls.client.markAsIrrelevant, {
          data: data,
          onSuccess: successHandler,
          onFinally: finallyHandler,
        });
      }
    },
  },
};
</script>
<style scoped>
.title-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}
.sub-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
</style>
 


