<template>
  <v-bottom-sheet v-model="show" persistent hide-overlay no-click-animation>
    <v-sheet
      elevation="0"
      height="80px"
      class="d-flex align-center justify-center"
      style="gap: 20px"
    >
      <v-btn
        outlined
        color="primary"
        class="text-capitalize rounded-lg"
        @click="openAssignSalesModal"
        >Assign Sales</v-btn
      >
      <v-dialog v-model="unAssignCnfDialog" width="280">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            color="primary"
            class="text-capitalize rounded-lg"
            v-bind="attrs"
            v-on="on"
            >Unassign Sales</v-btn
          >
        </template>
        <v-card>
          <v-card-title class="text-h6">
            Are you sure you want to unassign sales?
          </v-card-title>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              color="error"
              class="text-capitalize rounded-lg"
              @click="unAssignCnfDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              class="text-capitalize rounded-lg"
              @click="unAssignSales"
            >
              Unassign
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>

    <select-executive-modal @selected="onExecutiveSelected" />
  </v-bottom-sheet>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import SelectExecutiveModal from "@/components/selectExecutiveModal";

export default {
  components: { SelectExecutiveModal },
  data() {
    return {
      unAssignCnfDialog: false,
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.openBottomSheet();
      } else this.closeBottomSheet();
    },
  },
  computed: {
    ...mapGetters({
      bottomSheet: "clientManagement/bottomSheet",
    }),
    show: {
      get() {
        return this.bottomSheet.show;
      },
      set() {},
    },

    selectedLeads() {
      return this.bottomSheet.selectedClients;
    },
  },

  methods: {
    ...mapActions({
      toggleExecutiveModal: "caseManagement/executiveModal",
      toggleBottomSheet: "clientManagement/toggleBottomSheet",
      showSnackbar: "app/showSnackbar",
    }),

    openBottomSheet() {},

    closeBottomSheet() {
      this.toggleBottomSheet({ show: false });
    },

    openAssignSalesModal() {
      this.toggleExecutiveModal({
        show: true,
        type: "assign_sales",
      });
    },

    onExecutiveSelected(selected_id) {
      const data = {
        sales: selected_id,
        client: this.selectedLeads,
      };

      const onSuccess = () => {
        this.toggleExecutiveModal({
          id: "",
          show: false,
          type: "",
        });
        this.$emit("success-callback");
      };

      this.$request(this.$keys.POST, this.$urls.client.assignSales, {
        data,
        onSuccess,
      });
    },

    unAssignSales() {
      const data = {
        client: this.selectedLeads,
      };

      const onSuccess = () => {
        this.$emit("success-callback");
      };

      this.$request(this.$keys.POST, this.$urls.client.unAssignSales, {
        data,
        onSuccess,
      });
    },
  },
};
</script>