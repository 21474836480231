export default [
  {
    label: `Open Leads`,
    key: "open",
    value: 0,
  },
  {
    label: `Assigned to me Leads`,
    key: "assigned_to_me",
    value: 0,
  },
  {
    label: `Assigned to Sales Leads`,
    key: "assigned_to_sales",
    value: 0,
  },
  {
    label: `Query Mail Sent Leads`,
    key: "query_mail_sent",
    value: 0,
  },
  {
    label: `Follow-up Leads`,
    key: "follow_up",
    value: 0,
  },
  {
    label: `Irrelevant Leads`,
    key: "irrelevant",
    value: 0,
  },
  {
    label: `Case Added Leads`,
    key: "pre_converted",
    value: 0,
  },
  {
    label: `Converted Leads`,
    key: "converted",
    value: 0,
  },
];
