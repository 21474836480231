var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3 rounded-t-0",attrs:{"items-per-page":_vm.page_count,"server-items-length":_vm.page_count,"loading":_vm.loading,"loading-text":"Please wait.... Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":"","show-select":_vm.showSelect},on:{"dblclick:row":_vm.onItemDoubleClick},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.created))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-minus")])],1)]}},{key:"item.follow_up_date",fn:function(ref){
var item = ref.item;
return [(item.follow_up)?_c('div',[_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.follow_up.follow_up_date))+" ")]):_c('div',[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-minus")])],1)]}},{key:"item.follow_up_reason",fn:function(ref){
var item = ref.item;
return [(item.follow_up)?_c('div',[_vm._v(" "+_vm._s(item.follow_up.reason)+" ")]):_c('div',[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-minus")])],1)]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [(item.user.mobile || item.user.email)?_c('div',[(item.user.mobile)?_c('div',[_vm._v(" "+_vm._s(item.user.mobile_display)+" ")]):_vm._e(),(item.user.email)?_c('div',[_vm._v(" "+_vm._s(item.user.email)+" ")]):_vm._e()]):_vm._e()]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [(item.source)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","color":"primary","outlined":""}},[_vm._v(" "+_vm._s(item.source.name)+" ")]):_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-minus")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","color":"primary","outlined":""}},[_vm._v(" "+_vm._s(item.status_display)+" ")])]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [(item.remarks)?_c('div',[_vm._v(" "+_vm._s(item.remarks)+" ")]):_c('div',[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-minus")])],1)]}},{key:"item.assigned_sales",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4"},[(item.sales)?_c('div',[_c('div',[_vm._v(" "+_vm._s(item.sales.name)+" ")]),_c('div',{staticClass:"pointer d-flex justify-center primary--text",on:{"click":function($event){return _vm.assignSales(item.id)}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-pencil-outline ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v(_vm._s(["sales_head", "super_admin"].includes(_vm.user.user_type) ? "Change Sales" : "Unassign me")+" ")])],1)]):_c('div',[_c('div',{staticClass:"pointer primary--text",on:{"click":function($event){return _vm.executiveModal({
                id: item.id,
                show: true,
                type: 'assign_sales',
              })}}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v(" mdi-pencil ")]),_c('span',{staticClass:"executive ml-1"},[_vm._v("Assign Sales ")])],1)])])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"10px"}},[_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"outlined":"","color":"primary","height":"32px"},on:{"click":function($event){return _vm.clientDetail(item.id)}}},[_c('span',[_vm._v("View")])]),_c('v-menu',{attrs:{"left":"","offset-y":"","min-width":"200","width":"200","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var attrs = ref.attrs;
              var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize rounded-lg",attrs:{"outlined":"","color":"primary","height":"32px","width":"5px","min-width":"5px"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.actions),function(i){return _c('v-list-item',{key:i.key,attrs:{"link":""},on:{"click":function($event){return _vm.onActionClicked(i.key, item.id)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i.value)}})],1)}),1)],1)],1)]}}],null,true),model:{value:(_vm.selectedClients),callback:function ($$v) {_vm.selectedClients=$$v},expression:"selectedClients"}}),_c('v-container',{staticClass:"d-flex justify-space-between align-center px-0 mb-0 mt-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-2"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rows per page")]),_c('div',{staticStyle:{"width":"75px"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.itemsPerPageList,"hide-details":"","dense":"","solo":"","color":"primary"},model:{value:(_vm.page_count),callback:function ($$v) {_vm.page_count=$$v},expression:"page_count"}})],1),_c('div',{staticClass:"ml-6"},[_vm._v(" Results: "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageEnd)+" of "+_vm._s(_vm.total_count)+" ")])]),_c('v-pagination',{attrs:{"length":_vm.page_total,"total-visible":5,"color":"primary"},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1),_c('follow-up-modal'),_c('irrelevant-modal'),_c('select-executive-modal',{on:{"selected":_vm.onSelected}}),_c('bottom-sheet',{on:{"success-callback":_vm.bottomSheetSuccessCallback}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }